import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { Box, Button, Card, CardContent, CardHeader, Divider, useTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const Sales = (props) => {
    const theme = useTheme();
    const [dataTable, setData] = useState();
    const [startDate, setStartDate] = useState(null);
    const [load, setLoad] = useState(false);
    const [load1, setLoad1] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    const [people, setPeople] = useState(0);
    const [fullData, setFull] = useState(null);
    const [casino, setCasino] = useState(null);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    useEffect(() => {
        if (load1 == false) {
            if (props?.data?.tongnaps) {
                setData({
                    datasets: [
                        {
                            backgroundColor: "#3F51B5",
                            barPercentage: 0.5,
                            barThickness: 12,
                            borderRadius: 4,
                            categoryPercentage: 0.5,
                            data: [props?.data?.tongnaps, props?.data?.tongkms, props?.data?.tongruts],
                            label: "Thành công",
                            maxBarThickness: 10
                        },
                        {
                            backgroundColor: "#ffb020",
                            barPercentage: 0.5,
                            barThickness: 12,
                            borderRadius: 4,
                            categoryPercentage: 0.5,
                            data: [props?.data?.tongnapp, props?.data?.tongkmp, props?.data?.tongrutp],
                            label: "Chờ duyệt",
                            maxBarThickness: 10
                        }
                    ],
                    labels: ["Nạp", "Khuyến mãi", "Rút"]
                });
                setFull(props?.data);
                setPeople(props?.data?.tongnguoichoi);
                setLoad1(true);
            }
        }
    }, [{ ...props }]);
    const dataDefault = {
        datasets: [
            {
                backgroundColor: "#3F51B5",
                barPercentage: 0.5,
                barThickness: 12,
                borderRadius: 4,
                categoryPercentage: 0.5,
                data: [0, 0, 0, 0],
                label: "Thành công",
                maxBarThickness: 10
            },
            {
                backgroundColor: "#ffb020",
                barPercentage: 0.5,
                barThickness: 12,
                borderRadius: 4,
                categoryPercentage: 0.5,
                data: [0, 0, 0, 0],
                label: "Chờ duyệt",
                maxBarThickness: 10
            }
        ],
        labels: ["Nạp", "Khuyến mãi", "Rút"]
    };
    useEffect(() => {
        if (load == true) {
            axios.get(`${process.env.REACT_APP_API_URL}/statistic/getbydayadmin?dateStart=${startDate}&endDate=${endDate}`).then((res) => {
                setFull(res.data.data);
                setData({
                    datasets: [
                        {
                            backgroundColor: "#3F51B5",
                            barPercentage: 0.5,
                            barThickness: 12,
                            borderRadius: 4,
                            categoryPercentage: 0.5,
                            data: [res.data.data.tongnaps, props?.data?.tongkms, res.data.data.tongruts],
                            label: "Thành công",
                            maxBarThickness: 10
                        },
                        {
                            backgroundColor: "#ffb020",
                            barPercentage: 0.5,
                            barThickness: 12,
                            borderRadius: 4,
                            categoryPercentage: 0.5,
                            data: [res.data.data.tongnapp, props?.data?.tongkmp, res.data.data.tongrutp],
                            label: "Chờ duyệt",
                            maxBarThickness: 10
                        }
                    ],
                    labels: ["Nạp", "Khuyến mãi", "Rút"]
                });
                setPeople(res.data.data.tongnguoichoi);
            });
        }
    }, [endDate, startDate, load]);
    const options = {
        animation: false,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        xAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary
                },
                gridLines: {
                    display: false,
                    drawBorder: false
                }
            }
        ],
        yAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                    beginAtZero: true,
                    min: 0
                },
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider
                }
            }
        ],
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: "index",
            titleFontColor: theme.palette.text.primary
        }
    };
    useEffect(() => {
        axios.post(`https://server.top88xs88.com/casino/Seamless/History`, {}).then((res) => {
            setCasino(res.data.data);
        });
    }, []);
    return (
        <Card {...props}>
            <CardHeader title="Tổng nạp rút" />
            <Divider />
            <CardContent>
                <Box
                    sx={{
                        height: 400,
                        position: "relative"
                    }}>
                    {dataTable ? <Bar data={dataTable} options={options} /> : <Bar data={dataDefault} options={options} />}
                </Box>
            </CardContent>
            <Divider />
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
                <div style={{ padding: "10px", textAlign: "left" }}>
                    Tổng cược xs 3 miền: <b>{fullData?.tongcuoc?.toLocaleString("vi-VN")}</b>
                </div>
                <div style={{ padding: "10px", textAlign: "left" }}>
                    Tổng thắng xs 3 miền: <b>{fullData?.tongthang?.toLocaleString("vi-VN")}</b>
                </div>
                <div style={{ padding: "10px", textAlign: "left" }}>
                    Tổng chờ trả xs 3 miền: <b>{fullData?.tongcho?.toLocaleString("vi-VN")}</b>
                </div>
            </div>

            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                {startDate != null && fullData?.tongcuocapi > 0 && (
                    <>
                        <div style={{ padding: "10px", textAlign: "left" }}>
                            Tổng cược casino: <b>{fullData?.tongcuocapi?.toLocaleString("vi-VN")}</b>
                        </div>
                        <div style={{ padding: "10px", textAlign: "left" }}>
                            Tổng thắng casino: <b>{fullData?.tongthangapi?.toLocaleString("vi-VN")}</b>
                        </div>
                    </>
                )}
                <div style={{ padding: "10px", textAlign: "left" }}>
                    Tổng người tham gia: <b>{people}</b>
                </div>
                <div style={{ padding: "10px", textAlign: "left" }}>
                    Tổng khuyến mãi: <b>{fullData?.tongkms?.toLocaleString("vi-VN")}</b>
                </div>
                <div style={{ padding: "10px", textAlign: "left" }}>
                    Tổng nạp thành công: <b>{fullData?.tongnaps?.toLocaleString("vi-VN")}</b>
                </div>
                <div style={{ padding: "10px", textAlign: "left" }}>
                    Tổng nạp chờ duyệt: <b>{fullData?.tongnapp?.toLocaleString("vi-VN")}</b>
                </div>
                <div style={{ padding: "10px", textAlign: "left" }}>
                    Tổng rút thành công: <b>{fullData?.tongruts?.toLocaleString("vi-VN")}</b>
                </div>
                <div style={{ padding: "10px", textAlign: "left" }}>
                    Tổng rút chờ duyệt: <b>{fullData?.tongrutp?.toLocaleString("vi-VN")}</b>
                </div>
                <div style={{ padding: "10px", textAlign: "left" }}>
                    Tổng cược minigame: <b>{fullData?.tongcuocminigame?.toLocaleString("vi-VN")}</b>
                </div>
                <div style={{ padding: "10px", textAlign: "left" }}>
                    Tổng thắng minigame: <b>{fullData?.tongthangminigame?.toLocaleString("vi-VN")}</b>
                </div>
            </div>
            <Divider />
            <Box>
                <div className="col-1-1">
                    Từ ngày
                    <DatePicker
                        maxDate={new Date()}
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(date);
                            setLoad(true);
                        }}
                    />
                </div>
                <div className="col-1-1">
                    Đến ngày
                    <DatePicker
                        maxDate={new Date()}
                        selected={endDate}
                        onChange={(date) => {
                            setEndDate(date);
                            setLoad(true);
                        }}
                    />
                </div>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2
                }}
                style={{ width: "100%", padding: "15px 0 0" }}>
                <Button
                    className="button-admin"
                    color="primary"
                    endIcon={<ArrowRightIcon fontSize="small" />}
                    size="small"
                    onClick={() => {
                        setStartDate(null);
                        setEndDate(new Date());
                        setData({
                            datasets: [
                                {
                                    backgroundColor: "#3F51B5",
                                    barPercentage: 0.5,
                                    barThickness: 12,
                                    borderRadius: 4,
                                    categoryPercentage: 0.5,
                                    data: [props?.data?.tongnaps, props?.data?.tongkms, props?.data?.tongruts],
                                    label: "Thành công",
                                    maxBarThickness: 10
                                },
                                {
                                    backgroundColor: "#ffb020",
                                    barPercentage: 0.5,
                                    barThickness: 12,
                                    borderRadius: 4,
                                    categoryPercentage: 0.5,
                                    data: [props?.data?.tongnapp, props?.data?.tongkmp, props?.data?.tongrutp],
                                    label: "Chờ duyệt",
                                    maxBarThickness: 10
                                }
                            ],
                            labels: ["Nạp", "Khuyến mãi", "Rút"]
                        });
                        setPeople(props?.data?.tongnguoichoi);
                    }}>
                    Xem tất cả thời gian
                </Button>
            </Box>
        </Card>
    );
};
