import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import { Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow, Pagination, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function HistoryCasino() {
    function formatDate(m) {
        m.setUTCHours(m.getUTCHours() + 7);
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [data, setData] = useState(null);
    const [load, setLoad] = useState(true);
    const [endDate, setEndDate] = useState(new Date());
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [searched, setSearched] = useState("");
    const [pages, setPage] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = JSON.parse(localStorage.getItem("historyapi")).filter((row) => {
                const a = row?.MemberName?.toLowerCase().includes(searchedVal.toString().toLowerCase());
                return a;
            });
            setData(filteredRows);
            setPage(1);
        } else {
            setData(JSON.parse(localStorage.getItem("historyapi")));
        }
    };
    const handleChange = (e, page) => {
        setPage(page);
    };
    useEffect(() => {
        if (load === true) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/Seamless/History`, {})
                .then((res) => {
                    localStorage.setItem("historyapi", JSON.stringify(res.data.data));
                    setData(res.data.data);
                    setLoad(false);
                })
                .then((res) => setLoad(true));
        }
    }, [load]);
    const selectHistory = (date) => {
        setLoading(true);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/Seamless/History`, { date: formattedDate })
            .then((res) => {
                localStorage.setItem("historyapi", JSON.stringify(res.data.data));
                setData(res.data.data);
                setLoading(false);
            })
            .then((res) => {
                setLoad(true);
                setLoading(false);
            });
    };
    const gameType = (data) => {
        let a;
        switch (data.toString()) {
            case "1":
                a = "Slot";
                break;
            case "2":
                a = "Live Casino";
                break;
            case "3":
                a = "Sport Book";
                break;
            case "4":
                a = "Virtual Sport";
                break;
            case "13":
                a = "ESport";
                break;
            case "12":
                a = "Jackpot";
                break;
            case "11":
                a = "Bonus";
                break;
            case "10":
                a = "Cock Fighting";
                break;
            case "9":
                a = "Other";
                break;
            case "8":
                a = "Fishing";
                break;
            case "7":
                a = "P2P";
                break;
            case "39":
                a = "PP";
                break;
            case "6":
                a = "Qipai";
                break;
            default:
                a = data;
                break;
        }
        return a;
    };
    const productId = (data) => {
        let a;
        switch (data.toString()) {
            case "1002":
                a = "Evolution";
                break;
            case "1003":
                a = "All Bet";
                break;
            case "1004":
                a = "Big Gaming";
                break;
            case "1006":
                a = "Pragmatic";
                break;
            case "1007":
                a = "PG Soft";
                break;
            case "1009":
                a = "CQ9";
                break;
            case "1012":
                a = "SBO";
                break;
            case "1020":
                a = "WM";
                break;
            case "1046":
                a = "IBC";
                break;
            case "1052":
                a = "Dream Gaming";
                break;
            case "1078":
                a = "CMD368";
                break;
            case "1085":
                a = "JPDB";
                break;
            case "1091":
                a = "Jili";
                break;
            default:
                a = data;
                break;
        }
        return a;
    };
    const status = (data) => {
        let a;
        switch (data.toString()) {
            case "100":
                a = "Chờ xử lý";
                break;
            case "101":
                a = "Đã giải quyết";
                break;
            case "102":
                a = "Vô hiệu hóa";
                break;
            default:
                a = data;
                break;
        }
        return a;
    };
    if (data !== null) {
        return (
            <>
                <ThemeProvider theme={theme}>
                    <DashboardLayout>
                        {
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    py: 1
                                }}>
                                {isLoading ? (
                                    <div className="loading" style={{ maxWidth: "100%" }}>
                                        <div className="loader"></div>
                                    </div>
                                ) : null}
                                <Container maxWidth={false}>
                                    <div className="container_set">Lịch sử API</div>
                                    <div className="form_set">
                                        <Box sx={{ minWidth: 600 }}>
                                            <TextField
                                                value={searched}
                                                onChange={(searchVal) => requestSearch(searchVal.target.value)}
                                                placeholder="Tìm kiếm"
                                                sx={{ marginBottom: "5px", paddingRight: "700px" }}
                                            />
                                            <DatePicker
                                                maxDate={new Date()}
                                                selected={endDate}
                                                onChange={(date) => {
                                                    setEndDate(date);
                                                    selectHistory(date);
                                                }}
                                            />
                                            <Table sx={{ width: 1200 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ padding: "10px" }}>ID gd</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Tài khoản</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Số tiền</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Tiền thắng</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Trò chơi</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Nhà cung cấp</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Trạng thái</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Thời gian</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {data && (
                                                    <TableBody>
                                                        {data?.slice((pages - 1) * 12, (pages - 1) * 12 + 12).map((item) => (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.WagerID}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.MemberName}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.BetAmount.toLocaleString("vi-VN")}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.PayoutAmount.toLocaleString("vi-VN")}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{gameType(item?.GameType)}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{productId(item?.ProductID)}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{status(item?.Status)}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{formatDate(new Date(item?.CreatedOn))}</TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}
                                                    </TableBody>
                                                )}
                                            </Table>
                                        </Box>
                                    </div>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            pt: 3
                                        }}>
                                        {data != null ? <Pagination color="primary" count={Math.floor(data.length / 12) + 1} size="small" onChange={handleChange} /> : null}
                                    </Box>
                                </Container>
                            </Box>
                        }
                    </DashboardLayout>
                </ThemeProvider>
            </>
        );
    }
}
export default HistoryCasino;
