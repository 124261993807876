import { Box, Container } from "@mui/material";
import swal from "sweetalert";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import { useEffect, useState } from "react";

function Setting() {
    const [setting, setSetting] = useState();
    const [load, setLoad] = useState(true);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        if (load === true) {
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setSetting(res.data.data[0]);
                setLoad(false);
            });
        }
    }, [load]);
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            id: setting?._id,
            cskh: e.target.cskh.value,
            minnap: Number(e.target.minnap.value),
            maxnap: Number(e.target.maxnap.value),
            minrut: Number(e.target.minrut.value),
            maxrut: Number(e.target.maxrut.value),
            mincuoc: Number(e.target.mincuoc.value),
            maxcuoc: Number(e.target.maxcuoc.value),
            maxlo: Number(e.target.maxlo.value),
            maxde: Number(e.target.maxde.value),
            kmnap: Number(e.target.kmnap.value),
            down_link: e.target.down_link.value,
            hoantra: Number(e.target.hoantra.value)
        };
        console.log(formData);
        axios
            .put(`${process.env.REACT_APP_API_URL}/setting/update`, formData)
            .then((res) => {
                setLoad(true);
                swal("Sửa thông tin trò chơi thành công!");
            })
            .catch((res) => setLoad(true));
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {profile.username == "admin1234" || profile.username == "admin12345" || profile.username == "admin000" ? (
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 8
                            }}>
                            <Container maxWidth={false}>
                                <div style={{ fontSize: "25px", fontWeight: 700 }}>Cài đặt hệ thống</div>
                                <div style={{ marginTop: "20px" }}>
                                    <form className="setting" onSubmit={handleSubmit}>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Link tải app</label>
                                                <input defaultValue={setting?.down_link} type="text" name="down_link" id="down_link" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Hoàn trả cược (%)</label>
                                                <input defaultValue={setting?.hoantra} type="text" name="hoantra" id="hoantra" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>CSKH</label>
                                                <input defaultValue={setting?.cskh} type="text" name="cskh" id="cskh" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Khuyến mãi nạp (%)</label>
                                                <input defaultValue={setting?.kmnap} type="text" name="kmnap" id="kmnap" className="input_setting" />
                                            </div>
                                        </div>
                                        <div style={{ width: "100%", flex: "0 0 100%", clear: "both" }}></div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Nạp tối thiểu</label>
                                                <input defaultValue={setting?.minnap} type="number" name="minnap" step="any" id="minnap" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Nạp tối đa</label>
                                                <input defaultValue={setting?.maxnap} type="number" name="maxnap" step="any" id="maxnap" className="input_setting" />
                                            </div>
                                        </div>
                                        <div style={{ width: "100%", flex: "0 0 100%", clear: "both" }}></div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Rút tối thiểu</label>
                                                <input defaultValue={setting?.minrut} type="number" name="minrut" step="any" id="minrut" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Rút tối đa</label>
                                                <input defaultValue={setting?.maxrut} type="number" name="maxrut" step="any" id="maxrut" className="input_setting" />
                                            </div>
                                        </div>
                                        <div style={{ width: "100%", flex: "0 0 100%", clear: "both" }}></div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Tiền cược tối thiểu</label>
                                                <input defaultValue={setting?.mincuoc} type="number" name="mincuoc" step="any" id="mincuoc" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Tiền cược tối đa</label>
                                                <input defaultValue={setting?.maxcuoc} type="number" name="maxcuoc" step="any" id="maxcuoc" className="input_setting" />
                                            </div>
                                        </div>
                                        <div style={{ width: "100%", flex: "0 0 100%", clear: "both" }}></div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Giới hạn cược lô</label>
                                                <input defaultValue={setting?.maxlo} type="number" name="maxlo" step="any" id="maxlo" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Giới hạn cược đề</label>
                                                <input defaultValue={setting?.maxde} type="number" name="maxde" step="any" id="maxde" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ clear: "both", width: "100%" }}>
                                            <button type="submit" className="btn_setting">
                                                Lưu
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Container>
                        </Box>
                    ) : (
                        <div>Bạn không có quyền truy cập</div>
                    )}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}

export default Setting;
