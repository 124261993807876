import "./App.css";
import { useLocation, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import PrivateCustomer from "./PrivateCustomer";
import Login from "./pages/user/Login";
// Admin
import HistoryBetAll from "./pages/admin/HistoryBetAll";
import BankAdmin from "./pages/admin/BankAdmin";
import Dashboard from "./pages/admin/Dashboard";
import Request from "./pages/admin/Request";
import Users from "./pages/admin/Users";
import Refund from "./pages/admin/Refund";
import Add from "./pages/admin/Add";
import UserProfile from "./pages/admin/UserProfile";
import ThongBao from "./pages/admin/ThongBao";
import Setting from "./pages/admin/Setting";
import Config from "./pages/admin/Config";
import SettingNotify from "./pages/admin/SettingNotify";
import Employee from "./pages/admin/Employee";
import AllEmployee from "./pages/admin/AllEmployee";
import UsersByEmployee from "./pages/admin/UsersByEmployee";
import SettingProfit from "./pages/admin/SettingProfit";
import HoaHong from "./pages/admin/HoaHong";
import HistoryCasino from "./pages/admin/HistoryCasino";
import TraThuong from "./pages/admin/TraThuong";
import BankOut from "./pages/admin/BankOut";
import SetXD45s from "./pages/admin/SetXD45s";
import SetXD60s from "./pages/admin/SetXD60s";
import SetXS75s from "./pages/admin/SetXS75s";
import SetXS120s from "./pages/admin/SetXS120s";
// Customer
import DashboardCustomer from "./pages/customer/DashboardCustomer";
import UsersByEmployeeNV from "./pages/customer/UsersByEmployeeNV";
import HistoryBetAllCustomer from "./pages/customer/HistoryBetAllCustomer";
import HistoryCasinoCustomer from "./pages/customer/HistoryCasinoCustomer";

function App() {
    const location = useLocation();
    return (
        <div className="App">
            <Routes>
                <Route element={<Login />} path="/" />
                <Route element={<Login />} path="/login" />
                <Route path="/admin" element={<PrivateRouteAdmin />}>
                    {/* Admin */}
                    <Route element={<Dashboard />} path="/admin" exact />
                    <Route element={<HistoryCasino />} path="/admin/historyapi" exact />
                    <Route element={<HistoryBetAll />} path="/admin/history" exact />
                    <Route element={<Request />} path="/admin/request" exact />
                    <Route element={<Add />} path="/admin/add" exact />
                    <Route element={<Refund />} path="/admin/refund" exact />
                    <Route element={<Users />} path="/admin/users" exact />
                    <Route element={<UserProfile />} path="/admin/user/:id" />
                    <Route element={<AllEmployee />} path="/admin/allNV" exact />
                    <Route element={<Employee />} path="/admin/employee" exact />
                    <Route element={<UsersByEmployee />} path="/admin/employee/:id" />
                    <Route element={<Setting />} path="/admin/setting" exact />
                    <Route element={<Config />} path="/admin/config" exact />
                    <Route element={<SettingProfit />} path="/admin/setting/profit" exact />
                    <Route element={<HoaHong />} path="/admin/setting/money" exact />
                    <Route element={<SettingNotify />} path="/admin/settingnotify" exact />
                    <Route element={<BankAdmin />} path="/admin/bank" exact />
                    <Route element={<ThongBao />} path="/admin/notification" />
                    <Route element={<TraThuong />} path="/admin/trathuong" />
                    <Route element={<BankOut />} path="/admin/bankout" />
                    <Route element={<SetXD45s />} path="/admin/setxd45s" />
                    <Route element={<SetXD60s />} path="/admin/setxd60s" />
                    <Route element={<SetXS75s />} path="/admin/setxs75s" />
                    <Route element={<SetXS120s />} path="/admin/setxs120s" />
                </Route>
                <Route path="/customer" element={<PrivateCustomer />}>
                    {/* Nhân viên */}
                    <Route element={<DashboardCustomer />} path="/customer" exact />
                    <Route element={<UsersByEmployeeNV />} path="/customer/usercustomer" exact />
                    <Route element={<HistoryCasinoCustomer />} path="/customer/historyapi" exact />
                    <Route element={<HistoryBetAllCustomer />} path="/customer/history" exact />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
