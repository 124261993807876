import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./users.css";
import axios from "axios";
import swal from "sweetalert";
import { Box, Button, Container, Input, Table, TableBody, TableCell, TableHead, TableRow, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function AllEmployee() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [data, setData] = useState(null);
    const [load, setLoad] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [balance, setBalance] = useState(0);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const navigate = useNavigate();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        if (load == false) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/payment/chuyentien`, {})
                .then((res) => {
                    setData(res.data.data);
                    localStorage.setItem("data", JSON.stringify(res.data.data));
                    setLoad(true);
                })
                .catch((err) => setLoad(true));
            axios
                .get(`${process.env.REACT_APP_API_URL}/bank/balance`, {})
                .then((res) => {
                    setBalance(res.data.data.result.points);
                    setLoading(false);
                })
        }
    }, [load]);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (load == true) {
            const formData = {
                name: e.target.name.value,
                amount: e.target.amount.value,
                bank_id: e.target.type.value,
                bank_account: e.target.bank_account.value,
                bank_no: e.target.bank_no.value
            };
            setLoading(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/bank/bankout`, formData)
                .then((res) => {
                    setLoad(false);
                    swal("Thành công", "Chuyển tiền thành công", "success");
                    setLoading(false);
                })
                .catch((res) => {
                    setLoad(false);
                    swal("Thông báo", "Đã xảy ra lỗi", "error");
                    setLoading(false);
                });
        }
    };
    const [pages, setPage] = useState(1);
    const [searched, setSearched] = useState("");
    const handleChange = (e, page) => {
        setPage(page);
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {profile.username == "admin1234" || profile.username == "admin12345" || profile.username == "admin000" ? (
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1
                            }}>
                            {isLoading ? (
                                <div className="loading" style={{ maxWidth: "100%" }}>
                                    <div className="loader"></div>
                                </div>
                            ) : null}
                            <Container maxWidth={false}>
                                <div style={{ fontSize: "25px", fontWeight: 700 }}>Chuyển khoản</div>
                                <div style={{ color: "#f00" }}>
                                    Số dư hiện tại: <b>{Number(balance).toLocaleString("vi-VN")}</b>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", margin: "20px 0 50px" }}>
                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <div style={{ width: "400px", marginBottom: "20px" }}>
                                                <label>Tên nhân vật</label>
                                                <input type="text" name="name" id="name" className="input_setting" required />
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ width: "400px", marginBottom: "20px" }}>
                                                <label style={{ marginRight: "10px" }}>Chọn ngân hàng</label>
                                                <select name="type" id="type" required>
                                                    <option value="MBBANK">MBBANK</option>
                                                    <option value="OCB">OCB</option>
                                                    <option value="VIB">VIB</option>
                                                    <option value="ACB">ACB</option>
                                                    <option value="DONGABANK">DONGABANK</option>
                                                    <option value="Eximbank">Eximbank</option>
                                                    <option value="BIDV">BIDV</option>
                                                    <option value="VietinBank">VietinBank</option>
                                                    <option value="AGRIBANK">AGRIBANK</option>
                                                    <option value="Vietcombank">Vietcombank</option>
                                                    <option value="Techcombank">Techcombank</option>
                                                    <option value="TPBANK">TPBANK</option>
                                                    <option value="SACOMBANK">SACOMBANK</option>
                                                    <option value="HDBANK">HDBANK</option>
                                                    <option value="MARITIMEBANK">MARITIMEBANK</option>
                                                    <option value="OCEANBANK">OCEANBANK</option>
                                                    <option value="SHB">SHB</option>
                                                    <option value="SEABANK">SEABANK</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ width: "400px", marginBottom: "20px" }}>
                                                <label>Tên tài khoản ngân hàng</label>
                                                <input type="text" name="bank_account" id="bank_account" className="input_setting" required />
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ width: "400px", marginBottom: "20px" }}>
                                                <label>Số tài khoản ngân hàng</label>
                                                <input type="text" name="bank_no" id="bank_no" className="input_setting" required />
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ width: "400px", marginBottom: "20px" }}>
                                                <label>Số tiền</label>
                                                <input type="number" name="amount" id="amount" className="input_setting" required />
                                            </div>
                                        </div>
                                        <button className="btn_setting">Xác nhận</button>
                                    </form>
                                </div>
                                <div className="container_set">Lịch sử giao dịch</div>
                                <div className="form_set">
                                    <Box sx={{ minWidth: 600 }}>
                                        <Table sx={{ width: 1200 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ padding: "10px" }}>Người thực hiện</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Chi tiết</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Số tiền</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Trạng thái</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Thời gian</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {data && (
                                                <TableBody>
                                                    {data?.slice((pages - 1) * 10, (pages - 1) * 10 + 10).map((item) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.user?.username}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.detail}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{Number(item?.money).toLocaleString("vi-VN")} ₫</TableCell>
                                                                {item?.status_payment === "Pending" ? (
                                                                    <TableCell sx={{ padding: "10px" }}>
                                                                        <Button type="text" color="warning">
                                                                            {item?.status_payment}
                                                                        </Button>
                                                                    </TableCell>
                                                                ) : null}
                                                                {item?.status_payment === "Success" ? (
                                                                    <TableCell sx={{ padding: "10px" }}>
                                                                        <Button type="text" color="success">
                                                                            {item?.status_payment}
                                                                        </Button>
                                                                    </TableCell>
                                                                ) : null}
                                                                {item?.status_payment === "Deny" ? (
                                                                    <TableCell sx={{ padding: "10px" }}>
                                                                        <Button type="text" color="error">
                                                                            {item?.status_payment}
                                                                        </Button>
                                                                    </TableCell>
                                                                ) : null}
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{formatDate(new Date(item?.createdAt))}</TableCell>
                                                            </TableRow>
                                                        </>
                                                    ))}
                                                </TableBody>
                                            )}
                                        </Table>
                                    </Box>
                                </div>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        pt: 3
                                    }}>
                                    {data != null ? <Pagination color="primary" count={Math.floor(data.length / 10) + 1} size="small" onChange={handleChange} /> : null}
                                </Box>
                            </Container>
                        </Box>
                    ) : (
                        <div>Bạn không có quyền truy cập</div>
                    )}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}
export default AllEmployee;
